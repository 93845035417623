<template>
  <div>
    
    <!-- 기본 메뉴 -->
    <component
      v-if="resolution.width <= resolution.notebook"
      :is="Menu"
      scope="index"
      :mgb="resolution.width <= resolution.mobile ? `2xs-neg` : ``"
    />

    <!-- 기본검색 -->
    <div
      class="box"
      :gap="resolution.width > resolution.mobile ? `xs` : `3xs`"
      halign="end"
      valign="end"
      :pd="resolution.width > resolution.notebook ? `4xl-x` : `md-x`"
      :mgb="resolution.width > resolution.notebook ? `xs-neg` : ``"
      pos="relative-1"
      break="wrap"
      mgt="xl"
    >
      <!-- 타이틀 -->
      <div
        v-if="resolution.width > resolution.mobile"
        :flex="resolution.width > resolution.notebook ? `1` : ``"
        :extent="resolution.width > resolution.notebook ? `` : `fb-100`"
        line="5xs-b-5xs"
        mgr="4xs"
        pdb="md"
      >
        <div line="3xs-l-secondary" mgl="2xs" pdl="sm" lh="md" fsize="lg" break="nowrap">
          <strong color="secondary" pdr="2xs">
            {{ pagination.params?.q }}
          </strong>
          <strong>
            {{ $t('base-result') }}
          </strong>
        </div>
      </div>
      <!-- 국가 -->
      <div
        :flex="resolution.width > resolution.notebook ? `` : `1`"
        :grow="resolution.width > resolution.notebook ? `` : `1`"
        :extent="resolution.width > resolution.mobile ? `` : `fb-50`"
        :style="resolution.width > resolution.notebook ? `min-width:230px;` : `min-width: 50%;`"
        media="mobile tablet order-1"
      >
        <v-select
          v-model="pagination.country"
          :key="`${$i18n.locale}-${pagination.country}`"
          :label="$t('game-country')"
          class="v-select-order-hidden dom-update-country"
          :items="pagination.countries"
          item-title="text"
          item-value="code"
          variant="solo-filled"
          :density="resolution.width > resolution.mobile ? `comfortable` : `compact`"
          :style="resolution.width > resolution.mobile ? `min-width:170px;` : ``"
          hide-details
          persistent-clear
        >
          <template v-slot:prepend-inner>
            <Image
              v-if="pagination.country"
              :image="globals.getImage(`/flag/country/${pagination.country.toLowerCase()}.webp`)"
              :width="20"
              :height="20"
            />
            <v-icon v-else icon="mdi-earth" />
          </template>
          <template v-slot:selection="{ item }">
            <p color="secondary" fsize="sm" break="ellipsis-1">
              {{ item.props.title }}
            </p>
          </template>
          <template v-slot:item="{ props, item }">
            <div class="box" mg="md-x">
              <v-btn
                @click="pagination.country = props.value"
                variant="flat"
                :color="props.value === pagination.country ? `secondary` : ``"
                :title="props.title"
                block
              >
                <template v-slot:prepend>
                  <Image
                    v-if="pagination.country"
                    :image="globals.getImage(`/flag/country/${item.value.toLowerCase()}.webp`)"
                    :width="13"
                    :height="13"
                  />
                </template>
                {{ props.title }}
              </v-btn>
            </div>
          </template>
        </v-select>
      </div>
      <!-- 초기화 버튼 ( 모바일용 ) -->
      <div
        v-if="resolution.width <= resolution.tablet"
        media="mobile tablet notebook order-2"
        class="box"
      >
        <v-btn
          variant="outlined"
          color="blue-grey-darken-1"
          :height="resolution.width > resolution.mobile ? 50 : 45.5"
          :title="$t('reset')"
          @click="reload"
        >
          <v-icon icon="mdi-reload" class="text-blue-grey-darken-1" />
        </v-btn>
      </div>
      <!-- 키워드 -->
      <div
        :flex="resolution.width > resolution.notebook ? `` : `1`"
        :grow="resolution.width > resolution.notebook ? `` : `1`"
        :extent="resolution.width <= resolution.notebook ? `fb-60` : ``"
        media="mobile notebook order-4"
        class="box"
      >
        <v-text-field
          v-model="pagination.q"
          :density="resolution.width > resolution.mobile ? `comfortable` : `compact`"
          :label="$t('search-placeholder')"
          :hint="$t('create-hashtag-title-hint')"
          class="bg-blue-grey-darken-3 detail-absolute dom-update-keyword notebook-hide-detail"
          variant="solo-filled"
          append-inner-icon="mdi-magnify"
          :maxlength="globalVariables.create.search.max"
          single-line
          flex="1"
          @keyup.enter="$emit('onSearch')"
          @click:append-inner="$emit('onSearch')"
          clearable
          :style="resolution.width > resolution.notebook ? resolution.width > resolution.tablet && resolution.width <= resolution.notebook ? `min-width:200px;` : `min-width:350px;` : ``"
        ></v-text-field>
      </div>
      <!-- 검색버튼 -->
      <div
        media="mobile tablet notebook order-5"
        :grow="resolution.width > resolution.min ? `` : `1`"
        class="box"
      >
        <v-btn
          :disabled="!pagination.filter"
          :height="resolution.width > resolution.mobile ? 50 : 44"
          :title="$t('search-btn')"
          @click="$emit('onSearch')"
          bg="secondary"
          fsize="md"
          block
        >
          <v-icon icon="mdi-magnify" mgt="4xs" />
          <span mgl="2xs">
            {{ $t('search-btn') }}
            <!-- {{ $t('search-filter') }} -->
          </span>
        </v-btn>
      </div>
      <!-- 초기화 버튼 -->
      <div
        v-if="resolution.width > resolution.tablet"
        media="mobile tablet notebook order-5"
        class="box"
      >
        <v-btn
          variant="outlined"
          color="blue-grey-darken-1"
          :height="resolution.width > resolution.mobile ? 50 : 42"
          :title="$t('reset')"
          @click="reload"
        >
          <v-icon icon="mdi-reload" class="text-blue-grey-darken-1" />
        </v-btn>
      </div>
    </div>

    <!-- 연도 -->
    <div pdb="lg" style="overflow:hidden;">
      <div
        v-if="pagination.yearModel"
        container="4xl"
        mgl="auto"
        mgr="auto"
        :mgt="resolution.width > resolution.mobile ? `7xl` : `4xl`"
        class="box"
        pos="relative-1"
        animation="layout-transition"
        media="tablet"
      >
        <v-range-slider
          v-model="pagination.year"
          :ticks="pagination.years"
          :min="globalVariables.create.year.min"
          :max="globalVariables.create.year.max"
          :step="globalVariables.create.year.step"
          :fsize="resolution.width > resolution.notebook ? `` : `2xs`"
          color="secondary"
          thumb-label="always"
          show-ticks="always"
          thumb-size="15"
          tick-size="4"
          hide-details
          pdl="2xs"
          pdr="2xs"
          @end="updateArtist()"
          @update:focused="$emit('updateYearToAge')"
        ></v-range-slider>
      </div>
    </div>
    <div
      class="box"
      halign="center"
      valign="center"
      :pdt="resolution.width > resolution.notebook ? `2xl` : `${resolution.width > resolution.tablet ? `md` : `${resolution.width > resolution.mobile ? `md` : ``}`}`"
      :pdl="resolution.width > resolution.notebook ? `4xl` : `md`"
      :pdr="resolution.width > resolution.notebook ? `4xl` : `md`"
      :gap="resolution.width > resolution.tablet ? `xl` : `xs`"
      :mgb="resolution.width > resolution.notebook ? `` : resolution.width > resolution.mobile ? `xl` : `3xs`"
      media="tablet"
    >
      <div v-if="!pagination.yearModel || resolution.width > resolution.mobile" class="box" color="secondary" halign="center">
        <strong v-if="!pagination.yearModel" break="ellipsis-1" :fsize="resolution.width > resolution.mobile ? `` : `sm`">
          📆 {{ $t('create-year-age') }}
        </strong>
        <strong v-else class="text-purple-lighten-2">
          <v-icon icon="mdi-swap-horizontal" />
          {{ $t('create-year-age-range') }}
        </strong>
      </div>
      <div class="box" gap="3xs" halign="center" media="order-1" :extent="resolution.width > resolution.min ? `` : `w-100`">
        <v-btn
          v-for="(age, age_index) in pagination.ages"
          :key="age_index"
          variant="flat"
          :color="age.code === pagination.age ? `purple-lighten-1` : ``"
          :bg="age.code === pagination.age ? `` : `4xs`"
          :class="resolution.width > resolution.min ? `` : `pl-2 pr-2`"
          :size="resolution.width > resolution.mobile ? `default` : `small`"
          :flex="resolution.width > resolution.min ? `` : `1`"
          @click="pagination.age = age.code"
          :title="age.text"
        >
          <span v-if="age_index === 0">~ </span>
          {{ age.text }}
          <span v-if="age_index + 1 >= pagination.ages.length"> ~</span>
        </v-btn>
        <v-btn
          v-if="pagination.yearModel && resolution.width > resolution.mobile"
          variant="flat"
          color="secondary"
          :size="resolution.width > resolution.mobile ? `default` : `small`"
          @click="$emit('onSearch')"
          :title="$t('search-btn')"
        >
          <v-icon icon="mdi-magnify" mgt="4xs" />
          <span mgl="2xs">
            {{ $t('search-btn') }}
          </span>
        </v-btn>
        <v-btn
          v-if="pagination.yearModel"
          :color="pagination.yearModel ? `red-lighten-1` : ``"
          :class="resolution.width > resolution.min ? `` : `pl-2 pr-2`"
          :size="resolution.width > resolution.mobile ? `default` : `small`"
          @click="reloadAge"
          :title="$t('close-btn')"
        >
          <v-icon icon="mdi-close" />
        </v-btn>
      </div>
    </div>

    <!-- 인기 키워드 -->
    <div
      v-if="artistKeyword.length"
      :gap="resolution.width > resolution.mobile ? `2xs` : `3xs`"
      class="box"
      pd="sm-x"
      mgt="lg"
      halign="center"
      break="wrap"
    >
      <v-btn
        variant="outlined"
        color="purple-lighten-1"
        :grow="resolution.width > resolution.mobile ? `` : `1`"
        size="small"
        label
        @click="updateArtist()"
      >
        <span color="white">
          {{ $t('game-favorite-artist') }}
        </span>
        <v-icon icon="mdi-reload" mgl="2xs" mgb="4xs-neg" fsize="xs" class="text-purple-lighten-2" />
      </v-btn>
      <v-btn
        v-for="(artist, index) in artistKeyword" :key="index"
        :color="pagination.q === artist.name ? `red-lighten-1` : `purple-lighten-3`"
        :variant="pagination.q === artist.name ? `flat` : `tonal`"
        :grow="resolution.width > resolution.mobile ? `` : `1`"
        bg="4xs"
        size="small"
        label
        @click="searchArtist(artist.name)"
      >
        <v-icon v-if="pagination.q === artist.name" icon="mdi-close" mgr="3xs" mgb="5xs-neg" />
        <span v-else>#</span>
        {{ artist.name }}
      </v-btn>
      <v-btn
        v-if="pagination.q && !hasArtistKeyword"
        color="red-lighten-1"
        :grow="resolution.width > resolution.mobile ? '' : '1'"
        variant="flat"
        size="small"
        label
        clearable
        @click="pagination.q = ''"
      >
        <v-icon icon="mdi-close" mgr="3xs" mgb="5xs-neg" />
        <span>
          {{ pagination.q }}
        </span>
      </v-btn>
    </div>

  </div>
</template>

<script lang="ts">
// import Menu from '~/component/game/melody/report/menu.vue'

export default {
  props: {
    resolution: {
      type: Object,
      default () {
        return {}
      }
    },
    pagination: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  setup (props) {

    // 글로벌 스토어
    const $i18n: any = ref(globalVariables.app.i18n)
    
    const artistData: any = ref([])
    const artistKeyword: any = ref([])
    const artistTimestemp: any = ref(0)

    // DOM 처리
    function optimizerDom () {
      const country = document.querySelector('.dom-update-country')
      country?.setAttribute('aria-label', $i18n.value.$t('game-country'))
      const country_inside = country?.querySelector('.v-field--center-affix')
      // country_inside?.setAttribute('aria-label', $i18n.value.$t('game-country'))
      // country_inside?.setAttribute('aria-labelledby', $i18n.value.$t('game-country'))
      country_inside?.removeAttribute('role')
      country_inside?.removeAttribute('aria-haspopup')
      country_inside?.removeAttribute('aria-expanded')
      country_inside?.removeAttribute('aria-owns')
      country_inside?.removeAttribute('aria-label')
      // country_inside?.setAttribute('aria-labelledby', 'game-creation-country-menu')
      const country_inside_input = country?.querySelector('input[type="text"]')
      country_inside_input?.setAttribute('aria-label', 'game-creation-country-menu')

      const keyword = document.querySelector('.dom-update-keyword')
      keyword?.setAttribute('aria-label', $i18n.value.$t('search-placeholder'))
      const keyword_inside = keyword?.querySelector('.v-field--center-affix')
      keyword_inside?.setAttribute('aria-label', $i18n.value.$t('search-placeholder'))
    }

    // 정적 파일 가져오기
    onMounted(() => {
      fetch('/data/artist.json')
        .then(response => response.json())
        .then(data => {
          artistData.value = data
      })
      optimizerDom()
    })

    let hasArtistKeyword: any = computed(() => {
      return artistKeyword.value.some((item: any) => {
        return item.name === props.pagination.q; // 여기에 'return' 문을 추가하세요.
      })
    })

    // 메모리 비우기
    function restore () {
      $i18n.value = null
      artistData.value = null
      artistKeyword.value = null
      artistTimestemp.value = null
      hasArtistKeyword = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    // 비동기 함수를 만들어서 컴포넌트를 로딩 ---------------------------------------------------------
    const Menu: any = shallowRef(null)
    const loadComponent = async () => {
      const Menu_load = await import('~/component/game/melody/report/menu.vue')
      Menu.value = Menu_load.default || Menu_load
    }
    loadComponent()
    // ---------------------------------------------------------------------------------------------

    return {
      artistData,
      artistKeyword,
      artistTimestemp,
      hasArtistKeyword,

      // 컴포넌트
      Menu
    }
  },
  watch: {
    'pagination.age' (to, from) {
      this.updateArtist()
    }
  },
  methods: {
    removeCountry () {
      this.pagination.country = null
    },
    // 초기화
    reload () {
      this.$router.push({
        path: '/',
        force: true
      })
    },
    // 나이만 초기화
    reloadAge () {
      this.$emit('clearAge')
      const route = useRoute()
      if (route.query.year) {
        this.pagination.age = ''
        this.pagination.year = [0, 0]
        this.pagination.yearModel = false
        this.$emit('onSearch')
      }
      if (route.query.year) {
      }
      // this.$router.push({
      //   path: '/',
      //   force: true
      // })
    },
    // 아티스트 업데이트
    async updateArtist () {
      const now = new Date().getTime()
      if (now - this.artistTimestemp < 100) {
        return
      }
      const result = this.artistData.filter((item: any) => {
        const [searchStart, searchEnd] = this.pagination.year
        return item.year >= searchStart && item.year <= searchEnd
      })
      this.artistTimestemp = new Date().getTime()
      this.artistKeyword = await globalFunctions.shuffleArray(result, 5)
    },
    // 아티스트 검색
    searchArtist (artist: string = '') {
      if (this.pagination.q === artist) {
        this.pagination.q = ''
      } else {
        this.pagination.q = artist
      }
    }
  }
}
</script>